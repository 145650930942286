import React, { TextareaHTMLAttributes } from "react";
import { ChangeHandler } from "react-hook-form";
import { ComponentProps } from "@stitches/react";

import Wrapper from "./Wrapper";

import { TextareaInput } from "./__styles__/TextInput";

type StrippedDownInputProps = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  "size" | "value" | "onChange"
>;

interface Props extends StrippedDownInputProps {
  name: string;
  value?: string | number | null;
  label?: Maybe<string>;
  compactLabel?: boolean;
  size?: "smaller" | "small" | "medium";
  error?: string;
  styleOverride?: any;
  placeholder?: string;
  minRows?: number;
  required?: boolean;
  description?: Maybe<string>;
}

const Textarea = ({
  name,
  value = null,
  onChange,
  label = null,
  compactLabel = false,
  size = "medium",
  error,
  styleOverride = null,
  placeholder,
  minRows = 5,
  required,
  description,
}: Props & {
  onChange?: (value: string) => void;
}) => (
  <Wrapper
    label={label}
    compactLabel={compactLabel}
    name={name}
    error={error}
    required={required}
    description={description}
  >
    <TextareaInput
      name={name}
      onChange={evt => onChange?.(evt.target.value)}
      size={size}
      error={!!error}
      style={styleOverride}
      id={name}
      value={value?.toString() ?? ""}
      placeholder={placeholder}
      minRows={minRows}
      maxRows={15}
    />
  </Wrapper>
);

export const ReactHookFormTextareaInput = React.forwardRef(
  (
    {
      name,
      onChange,
      label,
      compactLabel = false,
      size = "medium",
      error,
      styleOverride = null,
      placeholder,
      minRows = 5,
      ...props
    }: Omit<ComponentProps<typeof TextareaInput>, "error"> &
      Omit<Props, "value"> & { onChange?: ChangeHandler },
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    return (
      <Wrapper
        label={label}
        compactLabel={compactLabel}
        name={name}
        error={error}
        labelTabIndex={-1}
      >
        <TextareaInput
          name={name}
          onChange={onChange}
          size={size}
          error={!!error}
          style={styleOverride}
          id={name}
          placeholder={placeholder}
          minRows={minRows}
          maxRows={15}
          inputRef={ref as React.RefObject<HTMLTextAreaElement>}
          {...props}
        />
      </Wrapper>
    );
  }
);

export default Textarea;
